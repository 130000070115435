import { partition } from "@today/lib"

import type { Client } from "@today/api/taker"
import type { Station } from "@today/api/tracker"

export function getClientOptions(clients: Client[]) {
  clients.sort((a, b) => a.name.localeCompare(b.name))
  const TOP_CLIENT_NAMES = [
    "CJ대한통운",
    "한진",
    "롯데택배",
    "NS홈쇼핑",
    "홈앤쇼핑",
    "우진",
  ]
  const [testClients, realClients] = partition(clients, (client) =>
    client.name.includes("테스트")
  )
  const [largeClients, smallerClients] = partition(realClients, (client) =>
    TOP_CLIENT_NAMES.includes(client.name)
  )
  const [smeClients, regularClients] = partition(
    smallerClients,
    (client) => client.role === "SME"
  )
  const toOption = (client: Client) => ({
    id: client.clientId,
    label: client.name,
  })

  return {
    "주요 고객사":
      largeClients
        ?.sort(
          (a, b) =>
            TOP_CLIENT_NAMES.findIndex((name) => name === a.name) -
            TOP_CLIENT_NAMES.findIndex((name) => name === b.name)
        )
        .map(toOption) ?? [],
    "일반 고객사": regularClients?.map(toOption) ?? [],
    SME: smeClients?.map(toOption) ?? [],
    테스트: testClients?.map(toOption) ?? [],
  }
}

export function getStationsForFilter(stations: Station[]) {
  return stations
    .filter((s) => !["PICK_UP", "CLIENT_COLLECT"].includes(s.type))
    .sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
}
